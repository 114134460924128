import { useState } from 'react'
import * as S from './style'

import influencer1 from 'assets/img/influencer-1.png'
import influencer2 from 'assets/img/influencer-3.png'
import influencer3 from 'assets/img/influencer-2.png'

// import emBreve from 'assets/img/em-breve.png'
// import emAndamento from 'assets/img/em-andamento.png'
import finalizada from 'assets/img/finalizada.png'

import logoGlam from 'assets/img/logo-glamstar.webp'
import firstChallengePrizeImage from 'assets/img/challenges/1-colocada.png'
import secondChallengePrizeImage from 'assets/img/challenges/2-colocada.png'
import thirdChallengePrizeImage from 'assets/img/challenges/3-colocada.png'
import fourthChallengePrizeImage from 'assets/img/challenges/4-colocada.png'

import finalist1Img from 'assets/img/challenges/finalists/bianca-peres.jpg'
import finalist2Img from 'assets/img/challenges/finalists/carina-reis.jpg'
import finalist3Img from 'assets/img/challenges/finalists/julia-costa-reis.jpg'
import finalist4Img from 'assets/img/challenges/finalists/laissa-orsano.jpg'
import finalist5Img from 'assets/img/challenges/finalists/crisalis-aquino.png'
import crown from 'assets/img/challenges/finalists/crown.png'

import thumbChallenge11 from 'assets/img/challenges/about-thumb-11-challenge.png'

import challengePhase1Image from 'assets/img/challenges/etapa-1.png'
import challengePhase2Image from 'assets/img/challenges/etapa-2.png'
import challengePhase3Image from 'assets/img/challenges/etapa-3.png'

import serGlampartner from 'assets/img/venha-ser-glampartner.png'
import tcGlamstarPdf from 'assets/docs/TC-Glamstar.2022.v4-09.2022.pdf'

import dontMiss from 'assets/img/dont-miss.webp'
import youtube from 'assets/img/youtube.webp'
import instagram from 'assets/img/instagram.webp'
import tiktok from 'assets/img/tiktok.webp'
import facebook from 'assets/img/facebook.webp'

import { CloseO } from '@styled-icons/evil/CloseO'

import InfluencersSlider from 'components/InfluencersSlider'
import InfluencersCard from 'components/InfluencersCard'
import VideosSliderLastEditions from 'components/VideosSliderLastEditions'
import LastEditionVideos from 'components/LastEditionVideos'

import { useBreakpoint } from 'hooks/useBreakpoint'
import NextStepShape from 'components/NextStepShape'
import NextStepShapeBottom from 'components/NextStepShapeBottom'
import NextStepShapeLightPink from 'components/NextStepShapeLightPink'
import FaqElement from 'components/FaqElement'
import Button from 'components/Button'

import faqList from './faqList'
import { challengeWinners } from './challengesWinners'

import { WinnerElement } from 'components/WinnerElement'
import ChallengeSlider from 'components/challengeSlider'

export default function Home() {
  const { isMobile } = useBreakpoint(768)
  const displayArea = false

  const challengesFinalists = [
    {
      name: 'Laissa Orsano',
      instagram: 'laissaorsano',
      avatar: finalist4Img
    },
    {
      name: 'Júlia Costa Reis',
      instagram: 'jucostareis',
      avatar: finalist3Img
    },
    {
      name: 'Carine Reis',
      instagram: '_carinereis',
      avatar: finalist2Img
    },
    {
      name: 'Bianca Peres',
      instagram: 'biancacperes',
      avatar: finalist1Img
    },
    {
      name: 'Crisális de Aquino',
      instagram: 'crisalisaquino',
      avatar: finalist5Img
    }
  ]

  const videosChallenge = [
    {
      challenge: 11,
      thumbnail: thumbChallenge11,
      videoUrl:
        'https://player.vimeo.com/video/746662497?h=2c620ad0d1&title=0&byline=0&portrait=0',
      title: `11º challenge NO AR!`,
      description: `Assinatura Glambox Quiz`
    }
  ]

  const [showModal, setShowModal] = useState('')
  const [openAnswer, setOpenAnswer] = useState(-1)
  const [openChallengeWinner, setOpenChallengeWinner] = useState<number[]>([])

  const handleOpenAnswer = (idx: number) => {
    setOpenAnswer(idx)

    if (openAnswer == idx) {
      setOpenAnswer(-1)
    }
  }

  const handleOpenChallengeWinners = (idx: number) => {
    setOpenChallengeWinner((arr) => [...arr, idx])
    if (openChallengeWinner.indexOf(idx) !== -1) {
      const toRemoveIdx = openChallengeWinner.filter((id) => id !== idx)
      setOpenChallengeWinner(toRemoveIdx)
    }
  }

  return (
    <S.Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0 }}
    >
      {showModal && (
        <S.Modal>
          <CloseO onClick={() => setShowModal('')} />
          <div className="content">
            <img src={'https://via.placeholder.com/140x80'} alt="140x100" />
            <iframe
              width="956"
              height="538"
              src={showModal}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        </S.Modal>
      )}
      <S.Banner>
        {!isMobile && (
          <img
            className="logo-glam"
            src={logoGlam}
            alt="seja a próxima glamstar"
          />
        )}
        <S.BannerContent>
          <div>
            <h1>Glamstar 2022</h1>
            <h2>Saiba tudo sobre a competição</h2>
            {/* <Button link="/inscricoes">Quero me inscrever</Button> */}
          </div>
        </S.BannerContent>
      </S.Banner>

      <S.PrizeSection>
        <NextStepShape
          className="bottom-shape"
          color="purple"
          topDesk={!isMobile}
        />
        <NextStepShape
          className="bottom-shape"
          color="purple"
          topMobile={isMobile}
        />

        <S.PrizeContainer>
          <div className="prize-content">
            <h1>O grande prêmio</h1>
            <p>
              Vamos selecionar as 4 mais novas influencers do Brasil que
              ganharão um contrato exclusivo com a Glambox.
            </p>
          </div>
        </S.PrizeContainer>

        <S.PrizeBox>
          <img src={firstChallengePrizeImage} />
          <img src={secondChallengePrizeImage} />
          <img src={thirdChallengePrizeImage} />
          <img src={fourthChallengePrizeImage} />
        </S.PrizeBox>

        {/* <a className="subscribe" href="/inscricoes" target="blank">
          <Button color="red">Quero me inscrever</Button>
        </a> */}
      </S.PrizeSection>

      {displayArea && (
        <S.TopVideoContainer>
          {!isMobile && (
            <h1 className="title-content">Assista os nossos challenges</h1>
          )}
          <div className="content">
            {isMobile && (
              <h1 className="title-content">Assista os nossos challenges</h1>
            )}
            <div className="main-video-content">
              {isMobile && (
                <div className="main-video-content">
                  <LastEditionVideos
                    callBack={() => setShowModal(videosChallenge[0].videoUrl)}
                    image={videosChallenge[0].thumbnail}
                  />
                  <div className="aside-right-side">
                    <div className="text">
                      <h1>{videosChallenge[0].title}</h1>
                      <p>{videosChallenge[0].description}</p>
                      {/* <Button
                      onClick={() => setShowModal(videosChallenge[0].videoUrl)}
                      color="red"
                    >
                      Assista agora
                    </Button> */}
                    </div>
                    <div className="divider"></div>
                    <S.ChallengeSliderContainer>
                      <ChallengeSlider
                        playVideo={(video: string) => setShowModal(video)}
                      />
                    </S.ChallengeSliderContainer>
                  </div>
                </div>
              )}
              {!isMobile && (
                <LastEditionVideos
                  callBack={() => setShowModal(videosChallenge[0].videoUrl)}
                  image={videosChallenge[0].thumbnail}
                />
              )}
              {!isMobile && (
                <div className="aside-right-side">
                  <div className="text">
                    <h1>{videosChallenge[0].title}</h1>
                    <p>{videosChallenge[0].description}</p>
                    {/* <Button
                    {/* <Button
                      onClick={() => setShowModal(videosChallenge[0].videoUrl)}
                      color="red"
                    >
                      Assista agora
                    </Button> */}
                    <div className="divider"></div>
                  </div>
                  <S.ChallengeSliderContainer>
                    <p className="title-challenge">
                      Assista a todos os challenges
                    </p>
                    <ChallengeSlider
                      playVideo={(video: string) => setShowModal(video)}
                    />
                  </S.ChallengeSliderContainer>
                </div>
              )}
            </div>
          </div>
        </S.TopVideoContainer>
      )}

      <S.ChallengeWinnersSection>
        <h1>Conheça as finalistas do Glamstar</h1>
        {/* <p>
          Nossas Glampartners estão arrasando na produção! Veja quem são as
          finalistas dos challenges e aproveite para se inspirar.
        </p> */}
        {displayArea ? (
          <S.ChallengeWinnersContent>
            {challengeWinners.map((winner, index) => (
              <WinnerElement
                key={winner.challenge}
                winner={winner}
                opened={openChallengeWinner.indexOf(index) !== -1}
                callback={() => handleOpenChallengeWinners(index)}
              />
            ))}
          </S.ChallengeWinnersContent>
        ) : (
          <S.ChallengesFinalistsContainer>
            {challengesFinalists.map((finalist, index) => (
              <figure key={finalist.instagram}>
                {index == 0 && <img src={crown} alt={finalist.name} />}
                {index != 0 && <span className="white-space"></span>}
                <img
                  className="avatar"
                  src={finalist.avatar}
                  alt={finalist.name}
                />
                <figcaption>
                  <div className="position">
                    <span>{index + 1}º lugar</span>
                  </div>
                  <h2>{finalist.name}</h2>
                  {finalist.instagram && (
                    <a
                      href={`https://www.instagram.com/${finalist.instagram}`}
                      target="blank"
                    >
                      @{finalist.instagram}
                    </a>
                  )}
                </figcaption>
              </figure>
            ))}
          </S.ChallengesFinalistsContainer>
        )}
        <S.EventVideoContainer>
          <div className="event-video">
            <h1>Confira o grande evento da final do Glamstar</h1>
            <iframe
              src="https://player.vimeo.com/video/757361100?h=b6dd40cbbf&title=0&byline=0&portrait=0&transparent=0"
              width="592"
              height="333"
              allow="autoplay; fullscreen; picture-in-picture"
            ></iframe>
          </div>
        </S.EventVideoContainer>
        {/* <a
          className="subscribe"
          href="https://partner.glambox.com.br/glampartner/glamstar/desafios"
          target="blank"
        >
          <Button color="red">Quero participar também</Button>
        </a> */}
      </S.ChallengeWinnersSection>

      <S.PhasesSection>
        <div className="phase-header">
          <h1>Como funciona</h1>
        </div>
        <S.PhasesBox>
          <div className="phase">
            <img className="phase-img-header" src={challengePhase1Image} />
            <strong>Fase de grupos</strong>
            <span>(Challenges 1 a 8)</span>
            <img className="phase-img" src={finalizada} alt="Finalizada" />
            <p>
              Inscreva-se a cada novo Challenge, produza seu conteúdo e torça
              para ser uma das 10 selecionadas em cada para a próxima fase
            </p>
          </div>
          <div className="phase">
            <img className="phase-img-header" src={challengePhase2Image} />
            <strong>Fase eliminatória </strong>
            <span>(Challenges 9 a 11)</span>
            <img className="phase-img" src={finalizada} alt="Finalizada" />
            <p>As 80 finalistas competem em 3 challenges finais</p>
          </div>
          <div className="phase">
            <img className="phase-img-header" src={challengePhase3Image} />
            <strong>Fase final</strong>
            <span>(Challenge 12)</span>
            <img className="phase-img" src={finalizada} alt="Finalizada" />
            <p>
              4 finalistas chegam à grande final em um super evento em São
              Paulo. Serão 4 premiadas
            </p>
          </div>
        </S.PhasesBox>

        {/* <a
          className="subscribe"
          href="https://partner.glambox.com.br/glampartner/glamstar/desafios"
          target="blank"
        >
          <Button color="red">Quero me inscrever</Button>
        </a> */}
      </S.PhasesSection>

      <S.InfluencersContainer>
        <h1>Conheça as 3 madrinhas que apresentaram o Glamstar 2022</h1>
        <p>
          Essas 3 Glamqueens ajudaram na grande decisão final e coroaram as 4
          finalistas.
        </p>

        <S.Influencers>
          {isMobile ? (
            <InfluencersSlider />
          ) : (
            <>
              <InfluencersCard
                isClickable
                onClick={() => window.open('https://instagram.com/tata')}
                image={influencer1}
                title="Tata Estaniecki"
                text="Uma das maiores influenciadoras de beauty e lifestyle do Brasil e mamãe da baby mais fofa, vem com todo seu carisma compor nosso time de apresentadoras do Glamstar 2022."
              />
              <InfluencersCard
                isClickable
                onClick={() =>
                  window.open('https://www.instagram.com/camilaqueiroz/')
                }
                image={influencer3}
                title="Camila Queiroz"
                text="Atriz premiada e querida pelo público, Camila é puro sucesso e arrasa nas redes sociais com mais de 17 milhões de seguidores no Instagram e 3 milhões no TikTok. Ela vem para fechar com chave de ouro o time das madrinhas Glamstar!"
              />
              <InfluencersCard
                isClickable
                onClick={() =>
                  window.open('https://www.instagram.com/patriciaramos/')
                }
                image={influencer2}
                title="Patrícia Ramos"
                text="Para com isso!!! Patrícia Ramos vem com muito humor compor o nosso time de apresentadoras do Glamstar 2022. A influenciadora conta com mais de 3 milhões de seguidores no Tiktok e 2,6 milhões no Instagram."
              />
            </>
          )}
        </S.Influencers>
      </S.InfluencersContainer>

      <NextStepShapeBottom
        className="top-shape-pink"
        color="lightPink"
        topDesk={!isMobile}
      />
      <NextStepShapeBottom
        className="top-shape-pink"
        color="lightPink"
        topMobile={isMobile}
      />
      <S.PinkBannerContainer>
        <section className="content">
          <img src={serGlampartner} alt="calendário" />
          <div>
            <h1>Venha ser uma Glampartner</h1>
            <p>
              As Glampartners são influenciadoras parceiras da Glambox e podem
              ter de 50% a 100% de desconto na assinatura mensal do clube de
              beleza, remuneração por vendas com cupom de desconto exclusivo,
              publis de marcas queridinhas no mercado e muito mais!
            </p>
            <a className="subscribe" href="http://www.glampartners.com.br">
              <Button color="darkPink">Quero me inscrever</Button>
            </a>
          </div>
        </section>
      </S.PinkBannerContainer>
      <NextStepShapeBottom
        className="bottom-shape-pink"
        color="lightPink"
        upSideDown
        topMobile={isMobile}
      />
      <NextStepShapeBottom
        className="bottom-shape-pink"
        color="lightPink"
        upSideDown
        topDesk={!isMobile}
      />

      <S.SocialContainer>
        <img className="dont-miss" src={dontMiss} alt="não perca!" />
        <div className="text">
          <p>
            Siga a Glambox nas redes sociais para não perder nenhuma atualização
            da competição.
          </p>
          <div className="social">
            <a
              href="https://instagram.com/glamboxbrasil"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="instagram" />
            </a>
            <a
              href="https://www.facebook.com/glambox.br/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.tiktok.com/@glamboxbrasil"
              target="_blank"
              rel="noreferrer"
            >
              <img src={tiktok} alt="tiktok" />
            </a>
            <a
              href="https://youtube.com/c/glamboxbrasil"
              target="_blank"
              rel="noreferrer"
            >
              <img src={youtube} alt="youtube" />
            </a>
          </div>
        </div>
      </S.SocialContainer>

      <S.FaqContainer>
        <h1>Dúvidas frequentes Glamstar 2022</h1>
        <div className="questions">
          {faqList.slice(0, 4).map((item, idx) => {
            return (
              <>
                <FaqElement
                  key={idx}
                  openAnswer={openAnswer == idx}
                  callBack={() => handleOpenAnswer(idx)}
                  question={item.question}
                  answer={item.answer}
                />
              </>
            )
          })}
          <a href="/faq">Clique aqui e confira mais dúvidas</a>
        </div>
      </S.FaqContainer>

      <NextStepShapeLightPink
        className="bottom-shape-light-pink"
        color="lightPink"
        topMobile={isMobile}
      />
      <NextStepShapeLightPink
        className="bottom-shape-light-pink"
        color="lightPink"
        topDesk={!isMobile}
      />

      <S.LastEditionBottom>
        <div>
          <h1> #TBT: Edições do Glamstar </h1>
          <p>Enquanto isso relembre as outras edições do Glamstar</p>
        </div>
        <S.VideoSliderContainer>
          <VideosSliderLastEditions
            playVideo={(video: string) => setShowModal(video)}
          />
        </S.VideoSliderContainer>
      </S.LastEditionBottom>
      <S.TermsAndConditions>
        <p>
          Confira os{' '}
          <a href={tcGlamstarPdf} target="_blank" rel="noreferrer noopener">
            Termos e Condições
          </a>{' '}
          do Glamstar
        </p>
      </S.TermsAndConditions>
    </S.Wrapper>
  )
}
